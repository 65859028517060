@import '../../../shared.sass'

.Satisfaction
  background-color: $background_grey
  padding-top: 30px
  padding-bottom: 50px
  h2
    text-align: center
    padding: 20px 0 50px 0
  .Opinion
    width: 80%
    margin: 0 auto
    display: flex
    flex-direction: row
    justify-content: center
    justify-content: space-between
    .LeftColumn, .RightColumn
      .Score
        margin-top: 20px
        font-size: 30px
      .Stars
        margin-bottom: 20px
        svg
          color: orange
          font-size: 30px
      .MoreLink
        a
          color: $black
          text-decoration: none
    .LeftColumn
      flex-basis: 45%
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      img
        width: 100%
    .RightColumn
      display: flex
      flex-direction: column
      flex-basis: 45%
      text-align: center
      .Name
        margin-top: 50px
        font-weight: bold
        font-size: 40px
      .Comment
        font-size: 30px
      .Phrase
        margin-top: 50px
        font-style: italic
      .Button
        text-align: center
        margin-top: 20px
        button
          background-color: $red
          cursor: pointer
          border: 0
          padding: 10px 20px
          border-radius: 5px
          a
            text-decoration: none
            color: #fff
      .Score, .Stars, .MoreLink
        display: none

@media (max-width: 992px)
  .Satisfaction
    .Opinion
      flex-direction: column
      .LeftColumn
        .Score, .Stars, .MoreLink
          display: none
      .RightColumn
        .Name
          font-size: 30px
        .Comment
          font-size: 18px
        .Score, .Stars, .MoreLink
          display: block
