.Whatsapp
  z-index: 1
  position: fixed
  bottom: 10px
  right: 10px
  background-color: white
  border-radius: 100px
  padding: 10px
  img
    height: 100px
    width: 100px

@media (max-width: 600px)
  .Whatsapp
    img
      height: 50px
      width: 50px
