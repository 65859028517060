@import '../../../shared.sass'

.CarCard
  flex: 0 0 33.33%
  max-width: 500px
  margin-bottom: 50px
  position: relative
  .Sold
    position: absolute
    top: 30px
    right: 10px
    color: #fff
    padding: 5px 15px
    border-radius: 5px
    box-shadow: 0 5px 10px 0px rgba(0,0,0,0.6)
    background-color: $red
    transform: rotate(45deg)
  .Reserved
    position: absolute
    top: 30px
    right: 10px
    color: #fff
    padding: 5px 15px
    border-radius: 5px
    box-shadow: 0 5px 10px 0px rgba(0,0,0,0.6)
    background-color: #ffc107
    transform: rotate(45deg)
  .Image
    span
      width: 100%
      img
        width: 100%
        max-height: 500px
        object-fit: cover
  .FirstRow
    display: flex
    flex-direction: row
    justify-content: space-between
    margin-top: 10px
    .Model
      font-weight: bold
      font-size: 18px
    .Prices
      display: flex
      flex-direction: row
      font-size: 18px
      text-align: right
      .BlackPrice, .RedPrice
        .Price
          font-weight: bold
      .RedPrice
        .Price
          color: $red
      .Text
        font-size: 14px
        margin-left: 10px
        white-space: nowrap
  .SecondRow
    display: flex
    flex-direction: row
    justify-content: space-between
    .Data
      display: flex
      align-items: center
      font-size: 14px
    .MonthlyPrice
      background-color: #dc3545
      font-size: 18px
      color: #fff
      padding: 5px 10px
