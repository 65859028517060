.SearchInput {
  width: 100%;
}

.Icon {
  float: left;
  position: absolute;
  margin: 15px auto 0px 15px;
  color: #ababab;
}

.Input {
  border: 0;
  padding: 10px;
  border-radius: 25px;
  width: 50%;
  max-width: 550px;
  padding-left: 50px;
}

.Input:focus {
  outline: none;
}

.Input::placeholder {
  color: #ababab;
  font-weight: bold;
}

.Button {
  border: 0;
  background-color: #dc3545;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  padding: 11px 50px;
  border-radius: 25px;
  display: inline-block;
  margin-left: -150px;
  font-size: 16px;
}

.Button:focus {
  outline: none;
}

@media (max-width: 992px) {
  .Input {
    width: 80%;
  }

  .Button {
    display: block;
    margin: 0 auto;
    margin-top: 20px;
    width: 80%;
  }
}
