.Centered {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -200px;
}

.Centered img {
  max-width: 400px;
}

.Centered img {
  -webkit-animation: flip 5s infinite linear;
  animation: flip 5s infinite linear;
}

@keyframes flip {
  from {
    transform: rotateY(90deg)
  }
  to {
    transform: rotateY(0deg)
  }
}
