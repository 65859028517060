.Jumbotron
  min-height: 600px
  background-image: url(./background.webp)
  border-radius: 0 !important
  margin-top: -80px
  margin-bottom: 0 !important
  padding: 0 !important
  position: relative
  .Filter
    position: absolute
    background: rgba(0, 0, 0, .6)
    width: 100%
    height: 100%
    object-fit: cover
    display: flex
    flex-direction: column
    align-items: center
    text-align: center
    justify-content: center
    min-height: 600px
  h1
    line-height: 1.375em
    color: #fff
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5)
    margin: 0.1em 0
    font-family: Dosis
    font-weight: 600
    font-size: 50px
    margin-bottom: 20px
  .SubTitle
    font-size: 25px
    line-height: 40px
    color: #fff
    font-weight: 800
    margin-bottom: 50px

@media (max-width: 992px)
  .Jumbotron
    padding: 0 !important
    padding-bottom: 0 !important
    position: static
    .Filter
      position: static
      margin-top: 50px
      padding: 0 20px 50px 20px
    h1
      margin-top: 100px
      font-size: 30px
    .SubTitle
      font-size: 20px
      margin-bottom: 50px
      line-height: 25px
  .NotMobile
    display: none
