.LowPrices
  background-image: url(./low_prices.webp)
  background-repeat: no-repeat
  background-size: cover
  .Filter
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    background: rgba(0, 0, 0, .7)
    width: 100%
    height: 100%
    z-index: 0
    object-fit: cover
  .Text
    color: #fff
    font-size: 30px
    z-index: 1
    padding: 20px 0
    text-align: center

@media (max-width: 992px)
  .LowPrices
    background-position: center
    .Text
      margin: 0
      padding: 20px 0
      font-size: 20px
