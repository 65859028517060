@import '../../../shared.sass'

.ChangeCar
  background-color: #fff
  padding: 50px 0
  .Title
    text-align: center
    margin-bottom: 50px
    font-size: 40px
    span
      text-align: center
      color: $red
  .Subtitle
    background-color: $red
    text-align: center
    padding: 20px 0
    color: #fff
    font-size: 20px
  .Boxes
    display: flex
    flex-direction: row
    justify-content: center
    margin-top: 30px
    .Box
      flex-basis: 25%
      .Title
        font-size: 30px
        font-weight: bold
        margin-bottom: 20px
      .Text
        text-align: center
        font-size: 20px
      .Button
        text-align: center
        margin-top: 20px
        button
          background-color: $red
          cursor: pointer
          border: 0
          padding: 10px 20px
          border-radius: 5px
          color: #fff
          a
            text-decoration: none
            color: #fff

@media (max-width: 992px)
  .ChangeCar
    .Title
      font-size: 30px
    .Subtitle
      padding: 20px
    .Boxes
      flex-direction: column
      margin-top: 30px
      .Box
        padding: 0 20px
        margin-bottom: 20px
