@import '../../../../shared.sass'

.FooterCampaign
  color: #fff
  font-size: 20px
  .Policities
    background-color: $grey
    padding: 20px 20px
    margin-top: 20px
    display: flex
    flex-direction: row
    justify-content: center
    .Link
      margin: 0 20px
      a
        color: #fff

@media (max-width: 992px)
  .FooterCampaign
    .Policities
      flex-direction: column
      justify-content: center
      text-align: center
