.Centered {
  text-align: center;
  margin: 100px auto;
}

.Centered img {
  max-width: 400px;
}

/*.Centered {
  -webkit-animation: flip 5s infinite linear;
  animation: flip 5s infinite linear;
}

@keyframes flip {
  from {
    transform: rotateY(90deg)
  }
  to {
    transform: rotateY(0deg)
  }
}*/
