@import '../../../../shared.sass'

.Footer
  background-image: url(./background.jpg)
  background-position: center
  background-repeat: no-repeat
  background-size: cover
  .Filter
    background: rgba(0, 0, 0, .8)
    width: 100%
    height: 100%
    object-fit: cover
    color: #fff
    font-size: 16px
    .Columns
      padding-top: 50px
      display: flex
      flex-direction: row
      flex-wrap: wrap
      justify-content: center
      .Column
        flex-basis: 20%
        display: flex
        flex-direction: column
        border-bottom: 2px solid #fff
        padding-bottom: 20px
        margin-bottom: 20px
        .Title
          font-size: 24px
          margin-bottom: 20px
        .Link
          display: flex
          flex-direction: row
          align-items: center
          margin-bottom: 10px
          svg
            margin-right: 20px
          svg.SocialIcon
            font-size: 30px
          a
            color: #fff
            text-decoration: none
    .PolicyLinks
      display: flex
      flex-direction: row
      justify-content: center
      padding: 20px 0
      .Year, .Link
        margin: 0 20px
      .Link
        a
          text-decoration: none
          color: #fff

@media (max-width: 992px)
  .Footer
    .Filter
      .Columns
        width: 80%
        margin: 0 auto
        flex-direction: column
      .PolicyLinks
        flex-direction: column
        .Link, .Year
          text-align: center
          margin-bottom: 20px
