@import '../../../shared.sass'

.Brands
  display: flex
  flex-direction: row
  align-items: center
  background-color: $black
  justify-content: center
  h2
    color: #fff
    width: 80%
    margin: 0 auto
    padding: 50px 0
    display: none
  .Text
    flex-basis: 45%
    text-align: left
    color: #fff
    padding: 50px
    font-size: 25px
    h2
      width: 100%
      display: block
      margin-bottom: 50px
  .Logos
    flex-basis: 40%
    padding: 50px
    display: flex
    flex-direction: row
    flex-wrap: wrap
    justify-content: center
    padding-bottom: 50px
    .Logo
      flex-basis: 30%
      .Link
        text-decoration: none
        .Brand
          margin: 0 50px
          display: flex
          flex-direction: column
          justify-content: center
          .Img
            display: flex
            justify-content: center
            height: 150px
            img
              align-self: center
              max-width: 100px
              max-height: 100px
              &.Horizontal
                max-height: 50px
          h3
            color: #fff
            font-weight: bold
            font-size: 24px
            text-align: center

@media (max-width: 992px)
  .Brands
    h2
      display: block
    flex-direction: column
    .Text
      flex-basis: 100%
      font-size: 18px
      h2
        display: none
    .Logos
      display: flex
      flex-direction: row
      flex-wrap: wrap
      flex-basis: 100%
      padding: 0
      .Logo
        flex-basis: 50%
        .Link
          .Brand
            margin: 0
