@import '../../../shared.sass'

.MotivationText
  display: flex
  flex-direction: row
  justify-content: center
  .Text
    padding: 50px 50px
    flex-basis: 30%
    font-size: 22px
    span
      color: $red
  .Image
    flex-basis: 25%
    img
      max-height: 600px

@media (max-width: 992px)
  .MotivationText
    flex-direction: column
    .Text, .Image
      flex-basis: 100%
      font-size: 18px
      img
        width: 100%
