@import '../../../shared.sass'

.NoTimeWaste
  background-image: url('./no_time_waste.webp')
  background-repeat: no-repeat
  background-size: cover
  background-position: center
  .Filter
    min-height: 500px
    background: rgba(0, 0, 0, .6)
    width: 100%
    height: 100%
    z-index: 0
    object-fit: cover
    padding: 50px 0 50px 0
    display: flex
    align-items: center
    .Text
      color: #fff
      margin-left: 200px
      font-size: 50px
      font-weight: bold
  .Icons
    background-color: $grey
    display: flex
    flex-direction: row
    justify-content: center
    padding: 20px 0
    .Icon
      text-align: center
      flex-basis: 30%
      color: #fff
      display: flex
      flex-direction: row
      justify-content: center
      align-items: center
      svg
        flex-basis: 25%
        font-size: 60px
      span
        flex-basis: 40%
        font-size: 20px

@media (max-width: 992px)
  .NoTimeWaste
    .Filter
      min-height: auto
      justify-content: left
      .Text
        margin: 0
        text-align: left
        font-size: 25px
        padding-left: 50px
    .Icons
      flex-direction: column
      padding: 20px 0 10px 0
      .Icon
        display: flex
        flex-direction: row
        flex-basis: 100%
        margin-bottom: 20px
        svg
          flex-basis: 20%
          font-size: 30px
        span
          text-align: left
          flex-basis: 75%
          font-size: 16px
