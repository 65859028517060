.Navbar
  text-align: center
  min-height: 80px
  webkit-transition: background-color 500ms linear
  -ms-transition: background-color 500ms linear
  transition: background-color 500ms linear
  a
    color: white
    font-size: 18px
    font-weight: 800
    letter-spacing: 0px
    text-transform: uppercase
    margin: 0px 10px
.NavbarScrolled
  background-color: rgba(255, 255, 255, 0.9)
  text-align: center
  min-height: 80px
  webkit-transition: all 500ms linear
  -ms-transition: all 500ms linear
  transition: all 500ms linear
  a
    color: #757575
    font-size: 18px
    font-weight: 800
    letter-spacing: 0px
    text-transform: uppercase
    margin: 0px 10px

.navItem
  display: flex
  flex-direction: column
  justify-content: center


.Logo
  max-width: 250px
  margin-top: -5px

.LogoMobile
  margin-bottom: 10px
  .Logo
    width: 200px

@media (min-width: 992px)
  .LogoMobile
    display: none

@media (max-width: 992px)
  .LogoDesktop
    display: none

  .NavbarScrolled, .Navbar
    background-color: rgba(255, 255, 255, 1)
    border-bottom: 1px solid #eee
    text-align: center
    min-height: 80px
    a
      color: #757575
      font-size: 14px
      font-weight: 800
      letter-spacing: 0px
      text-transform: uppercase
      margin: 0px 10px
