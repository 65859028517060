.Reasons
  background-image: url(./reasons.webp)
  background-repeat: no-repeat
  background-position: center
  background-size: cover
  .Filter
    background: rgba(255, 255, 255, .9)
    width: 100%
    height: 100%
    z-index: 0
    object-fit: cover
    padding: 50px 0 30px 0
    .Icons
      width: 80%
      margin: 0 auto
      display: flex
      flex-direction: row
      flex-wrap: wrap
      justify-content: center
      .Icon
        flex-basis: 30%
        display: flex
        flex-direction: column
        justify-content: flex-start
        text-align: center
        align-items: center
        margin-bottom: 50px
        padding: 0 20px
        svg
          font-size: 50px
          margin-bottom: 20px
        span
          font-size: 16px

@media (max-width: 992px)
  .Reasons
    .Filter
      .Icons
        .Icon
          flex-basis: 100%
