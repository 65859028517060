@import '../../../shared.sass'

.Cookies
  z-index: 2
  position: fixed
  bottom: 0
  background-color: $grey
  width: 100%
  text-align: center
  padding: 10px 0
  color: #fff
  a
    color: #fff
  button
    margin-left: 20px
    background-color: $red
    border: 0
    padding: 10px 20px
    color: white
    border-radius: 50px
    outline: 0
