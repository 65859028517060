@import '../../../shared.sass'

.CarList
  .Title
    margin: 0 0 40px 0
    text-align: center
    background: $red
    color: #fff
    padding: 20px 0 20px 50px
  .Button
    text-align: center
    button
      background-color: $red
      cursor: pointer
      border: 0
      padding: 10px 20px
      border-radius: 5px
      a
        text-decoration: none
        color: #fff
  .Delivery
    text-align: center
    margin-bottom: 50px
    font-size: 14px
    margin-top: 10px
  .CarListContainer
    display: flex
    flex-flow: row wrap
    justify-content: space-evenly
    width: 80%
    margin: 0 auto
    .CarContainer
      flex-basis: 33.33333%
      padding: 0 20px
    a
      text-decoration: none
      color: #212529
    .CenteredElement
      text-align: center
      Button
        margin-top: -20px
        margin-bottom: 25px
        svg
          margin-right: 10px


@media (max-width: 992px)
  .CarList
    .Title
      padding: 20px 0
      h2
        padding: 0 20px
    .CarListContainer
      .CarContainer
        flex-basis: 100%
