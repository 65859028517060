@import '../../../shared.sass'

.HowItWorks
  background-color: #fff
  padding: 50px 0 50px 0
  h2
    text-align: center
    margin-bottom: 50px
    z-index: 1
  .Content
    width: 80%
    margin: 0 auto
    display: flex
    flex-direction: row
    .TextContainer, .BoxContainer
      flex-basis: 50%
    .TextContainer
      z-index: 1
      font-size: 24px
    .BoxContainer
      z-index: 1
      .Box
        border-radius: 10px
        color: #fff
        width: 80%
        background-color: $red
        margin: 0 auto
        padding: 20px 0
        .Title
          background-color: $grey
          padding: 20px 0
          text-align: center
          font-size: 30px
        .Text
          font-size: 24px
          text-align: center
          padding: 20px 20px
        .Button
          text-align: center
          button
            background-color: $background_grey
            cursor: pointer
            border: 0
            padding: 10px 20px
            border-radius: 5px
            color: $black
            a
              color: $black
              text-decoration: none

@media (max-width: 992px)
  .HowItWorks
    width: 100%
    .Content
      flex-direction: column
      .TextContainer
        text-align: center
        font-size: 18px
      .BoxContainer
        .Box
          width: 100%
          margin-top: 50px
          .Title
            font-size: 25px
